import React from "react";
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import styled from "styled-components";

const MapContainer = styled.div`
  .leaflet-container {
    height: 70vh;
    width: 70vw;
    max-width: 960px;
    margin-bottom: 3rem;
  }
`;

const OpenMarker = props => {
  const initMarker = ref => {
    if (ref) {
      ref.leafletElement.openPopup();
    }
  };

  return <Marker ref={initMarker} {...props} />;
};

const LeafletMap = ({ position, zoom }) => {
  if (typeof window !== "undefined") {
    return (
      <MapContainer>
        <Map center={position} zoom={zoom}>
          <TileLayer
            url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          <OpenMarker position={position}>
            <Popup permanent>
              <span>
                <strong>Haus Bergkristall</strong>
                <br />
                Plangeross 70
                <br />
                6481 St. Leonhard
                <br />
                <br />
                <a href="https://www.google.com/maps/dir//Haus+Bergkristall,+Plangeross+70,+6481,+Austria/@46.9870612,10.8671298,17z/data=!4m19!1m9!3m8!1s0x479d2b923436a31b:0xe6e1b0451ea4b9c8!2sHaus+Bergkristall!5m2!4m1!1i2!8m2!3d46.9870576!4d10.8693238!4m8!1m0!1m5!1m1!1s0x479d2b923436a31b:0xe6e1b0451ea4b9c8!2m2!1d10.8693238!2d46.9870576!3e3">
                  <FormattedMessage id="map_directions" />
                </a>
              </span>
            </Popup>
          </OpenMarker>
        </Map>
      </MapContainer>
    );
  }
  return null;
};

export default injectIntl(LeafletMap);
