import React from "react";
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl";

import { SEO, Layout } from "../components/";
import LeafletMap from "../components/LeafletMap.js";

const position = [46.987015, 10.869336];
const markerText = "Haus Bergkristall<br/>Plangeross 70<br/>6481 St. Leonhard";

const keywords = [
  "anreise",
  "reise",
  "tirol",
  "berge",
  "pitztal",
  "appartements",
  "ruhe",
  "genuss",
  "gletscher",
  "ski",
  "snowboard",
  "urlaub",
  "sommer",
  "winter",
  "sommerurlaub",
  "winterurlaub",
  "wellness",
  "gruppenhaus"
];

const LocationPage = ({ intl }) => {
  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: "how_to_find_us" })}
        keywords={keywords}
      />
      <h1>
        <FormattedMessage id="how_to_find_us" />
      </h1>
      <LeafletMap position={position} zoom={17} markerText={markerText} />
    </Layout>
  );
};

export default injectIntl(LocationPage);
